import './App.css';
import { FC } from 'react';

const App: FC = () => (
  <div
    className="App"
    style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
    Hello world vic and rach (:
  </div>
);
export default App;
